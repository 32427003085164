<template>

	<div class="page">
		<div class="view">
			<div>采购单提交成功</div>
			<div>我们将尽快联系您，为你配货</div>
			<div>采购负责人：王璇18538098847</div>
			<div>采购金额：￥{{ getallprice }}元</div>
			<div class="viewBtn">
				<el-button type="primary" @click="handleHome">进入首页</el-button>
				<el-button type="primary" @click="handleDetail">查看采购单</el-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			id: this.$route.query.cartId,
			getallprice: this.$route.query.getallprice
		}
	},
	mounted() {

	},
	methods: {
		handleHome() {
			this.$router.push({ path: 'supplyHome' })
		},
		handleDetail() {
			this.$router.push({ path: ' ',id: this.$route.query.cartId})
		}
	},
}
</script>
<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;
	display: flex;
	flex-direction: column;
	background: #fff;

	.view {
		width: 800px;
		height: 300px;
		margin: 100px auto;
		border-radius: 20px;
		overflow: hidden;
		border: 1px solid rgb(193, 191, 191, .2);
		text-align: center;
		padding: 20px 0;

		div {
			// margin: 0 auto;
			line-height: 40px;
		}
	}

	.viewBtn {
		display: flex;
		align-items: center;
		justify-content: space-around;
		font-size: 14px;
		margin-top: 60px;
	}
}
</style>
